
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import tags from "@/core/data/tags";
import { ITags } from "@/core/data/tags";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    VPagination,
    // ExportCustomerModal,
    // AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const checkedTags = ref([]);
    const showTable = ref(false);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "Title",
        key: "tag_name",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const limit = ref(10);
    const total_pages = ref(1);
    const tableData = ref<Array<ITags>>([]);
    const initCustomers = ref<Array<ITags>>([]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Tags", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      getItem(1);
    });

    const getItem = (page) => {
      var request = {
        url: `/tag?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );

          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteFewTags = () => {
      checkedTags.value.forEach((item) => {
        deleteTags(item);
      });
      checkedTags.value.length = 0;
    };

    const deleteTags = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `tag/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            showTable.value = false;
            Swal.fire("Deleted!", "Tags has been deleted.", "success");
            getItem(1);
          });
          // Swal.fire("Deleted!", "Tags has been deleted.", "success");
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<ITags> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };
    const page = ref(1);
    return {
      tableData,
      tableHeader,
      deleteFewTags,
      search,
      searchItems,
      checkedTags,
      deleteTags,
      showTable,
      getItem,
      dateTime,
      updateHandler,
      limit,
      total_pages,
      page
    };
  },
});
